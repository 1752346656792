import Vue from 'vue';
import Vuex from 'vuex';
import { roleType } from '/app.config';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // 00 系统管理员，01 实施人员，02 企业管理员，03 企业员工
    userRole: '',
    userInfo: {
      userType: '',
      tenantId: '',
      permissions: null // 当前用户所有权限，包含：菜单权限 menu、设备权限 devices、所管理的企业列表 tenants (只有系统管理人员和实施人员才有)
    }
  },
  getters: {
    getUserRole(state) {
      return state.userRole;
    },
    getUserInfo(state) {
      return state.userInfo;
    },
    // 是系统管理员吗?
    isAdmin(state) {
      return state.userRole === roleType.admin;
    },
    // 是实施人员吗?
    isPerform(state) {
      return state.userRole === roleType.perform;
    },
    // 是企业管理员吗?
    isCompanyAdmin(state) {
      return state.userRole === roleType.companyAdmin;
    },
    // 是企业员工吗?
    isCompanyUser(state) {
      return state.userRole === roleType.companyUser;
    }
  },
  mutations: {
    setUserInfo(state, userInfo) {
      if (userInfo) {
        const { userType } = userInfo;
        // 存储用户角色
        state.userRole = userType;
        // 存储用户全部信息
        state.userInfo = userInfo;
      }
    }
  },
  actions: {},
  modules: {}
});
