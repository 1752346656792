const APP_NAME = '设备互联云平台'; // 应用名称
const API_VERSION = '1.0.0'; // api 版本号
const RE_CLICK_TIMEER = 3000; // 重复点击限时
const SIMULATE_NETWORK_TIME = 200; // 模拟网络请求耗时
const offline = false; // 和服务端断开连接，意味着所有请求都将不会发送、也不会跳转至认证中心、且菜单使用本地菜单，通常用在服务端异常不可连接的情况下。
// 真假值常量 一般被映射为 “是” “否” 或 “有” “无”
const falsy = 0;
const truthy = 1;

// 用户角色类型
const roleType = {
  admin: '00', // 系统管理员
  perform: '01', // 实施人员
  companyAdmin: '02', // 企业管理员
  companyUser: '03' // 企业员工
};

// 设备状态
const deviceStatus = {
  offline: 'offline',
  free: 'free',
  running: 'running',
  alarm: 'alarm'
};

// scroll 配置
const SCROLL = {
  pull_threshold: 50
};

// 表格配置
const TABLE = {
  icons: {
    add: 'icon-dctianjia',
    edit: 'icon-dca-bianji1',
    del: 'icon-dcshanchu',
    view: 'view',
    more: 'icon-dcicon_more',
    fileExport: 'icon-dcdaochu1',
    download: 'icon-dcxiazai1'
  },
  // 列操作，大于指定阈值才显示更多 icon
  more_icons_threshold: 3,
  page_sizes: [20, 40, 60, 80, 100] // 分页大小
};

// 列表返回全部数据(临时)
const maxPageSize = 99999;

// 文件最大允许上传个数
const UPLOAD_LIMIT = 5;

export { APP_NAME, API_VERSION, deviceStatus, RE_CLICK_TIMEER, SIMULATE_NETWORK_TIME, offline, falsy, truthy, roleType, SCROLL, TABLE, maxPageSize, UPLOAD_LIMIT };
