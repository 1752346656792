/* eslint-disable prefer-promise-reject-errors */
import Vue from 'vue';
import confirm from './DelDialog/index.vue';
let DelRanderDom = Vue.extend(confirm);
let deldialog = function (content) {
  document.activeElement.blur();
  return new Promise((res, rejct) => {
    let confirmDom = new DelRanderDom({
      el: document.createElement('div')
    });
    document.body.appendChild(confirmDom.$el);
    confirmDom.content = content;
    confirmDom.ok = function () {
      res();
      confirmDom.content.Deldisable = false;
    };
    confirmDom.quit = function () {
      rejct();
    };
    confirmDom.close = function () {
      confirmDom.content.Deldisable = false;
    };
  });
};
export { deldialog };
