import Element from 'element-ui';
import STATUS_CODE from './code';
import $interfaces from './interfaces';

/**
 * 响应消息统一处理函数
 * @param {String} Object.method  http 请求的方法
 * @param {Number} Object.code    响应的业务状态码
 * @param {String} Object.message 响应的业务消息
 * @param {Error}  Object.error   http 响应的物理错误
 */
function messageHandle({ method, code, message }) {
  /**
   * 如果是 get 请求并且 code 正常，则直接返回
   * 因为往往在获取数据的时候并不需要类似"获取成功"这类的提示
   */
  if (method === 'get' && code === STATUS_CODE.OK) {
    return;
  }

  // 像 post、put、delete 这类写入类型的请求，则需要告诉用户是否操作成功
  switch (code) {
    case STATUS_CODE.OK:
      if (message && message !== 'success') {
        Element.Message.success(message);
      }
      break;
    case STATUS_CODE.LACK_PARAMS:
      Element.Message.warning(message);
      break;
    case STATUS_CODE.TOKEN_ERR:
    case STATUS_CODE.AUTH_ERR:
    case STATUS_CODE.SERVER_ERR:
      Element.Message.error(message);
      break;
  }
}

/**
 * 检查 get 请求响应头的 content-type 是否包含 application/json，前后端约定通常情况下非 application/json 类型都是文件流
 * @param {String} method http 请求的方法
 * @param {String} conType content-type 值
 * @return {Boolean}
 */
function resStream(method, contentType) {
  return method === 'get' && !contentType.includes('application/json');
}

/**
 * 将对象序列化为 URL 查询参数字符串
 * @param {Object} obj     要序列化为查询参数的对象
 * @param {String} begin   用于在查询参数中开始连接键值对的子字符串, 默认值: '？'
 * @param {String} sep     用于在查询参数中分隔键值对的子字符串, 默认值: '&'
 * @param {String} eq      用于在查询参数中分隔键和值的子字符串, 默认值: '='
 * @return {String} result 序列化后的查询参数
 */
const querystringify = (obj, begin = '?', sep = '&', eq = '=') => {
  let result = begin;
  const keys = Object.keys(obj);

  keys.forEach(key => {
    const param = obj[key];
    if (param instanceof Array) {
      param.forEach(value => {
        result += key + eq + value + sep;
      });
    } else {
      result += key + eq + param + sep;
    }
  });

  return result.slice(0, -1);
};

const toAuthorization = async () => {
  localStorage.clear();

  Element.Loading.service();

  const authorizeURL = await $interfaces.oauth.authorize();
  const currentAppURL = location.href;
  const callbackURL = authorizeURL + currentAppURL;

  location.replace(callbackURL);
};

/****
 *
 * 时间戳格式化工具
 * @param {String} fmt          格式化后的格式
 * @param {TimerString} timer   时间戳
 *
 *  */
const timerFormatter = (fmt, timer) => {
  let currentTime;
  currentTime = new Date(timer);

  let o = {
    'M+': currentTime.getMonth() + 1,
    'd+': currentTime.getDate(),
    'h+': currentTime.getHours(),
    'm+': currentTime.getMinutes(),
    's+': currentTime.getSeconds(),
    'q+': Math.floor((currentTime.getMonth() + 3) / 3),
    S: currentTime.getMilliseconds()
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, `${currentTime.getFullYear()}`.substr(4 - RegExp.$1.length));
  }
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
    }
  }
  return fmt;
};
/****
 *
 * 毫秒转hh:mm:ss格式化工具
 * @param {TimerString} timer   时间戳
 * @param {Boolean} isChinese   是否返回中文
 *
 *  */
const msFormatter = (timer, isChinese = false) => {
  let hh;
  let mm;
  let ss;
  hh = Math.floor(timer / 1000 / 60 / 60);
  mm = Math.floor((timer / 1000 / 60) % 60);
  ss = ((timer / 1000) % 60).toFixed(0);
  if (isChinese) {
    return `${hh}小时${mm}分钟`;
  }
  hh = hh < 10 ? '0'.concat(hh) : hh;
  mm = mm < 10 ? '0'.concat(mm) : mm;
  ss = ss < 10 ? '0'.concat(ss) : ss;
  return `${hh}:${mm}:${ss}`;
};
export { messageHandle, resStream, querystringify, toAuthorization, timerFormatter, msFormatter };
