import Vue from 'vue';
import VueRouter from 'vue-router';
// import { toAuthorization } from '@/axios/utils';
// import clearWindowInterVals from './clearWindowInterVals';
// import checkRouteAuth from './checkRouteAuth';
// import menuActive from './menuActive';
import { offline } from '/app.config';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: () => import('@/views/app/app'),
    children: [
      {
        path: '/factoryconfig',
        name: 'factoryconfig',
        component: () => import('@/views/factoryconfig/factoryconfig'),
        children: [
          {
            path: 'template',
            name: 'template',
            component: () => import('@/views/factoryconfig/template/template'),
            meta: {
              breadcrumb: ['工厂配置', '设备模板']
            }
          },
          {
            path: 'template/detail/:id',
            name: 'templateDetail',
            component: () => import('@/views/factoryconfig/template/detail'),
            props: true,
            meta: {
              breadcrumb: ['工厂配置', '设备模板', '模板详情']
            }
          },
          {
            path: 'template/add',
            name: 'templateAdd',
            component: () => import('@/views/factoryconfig/template/update/update'),
            meta: {
              breadcrumb: ['工厂配置', '设备模板', '添加模板']
            }
          },
          {
            path: 'template/edit',
            name: 'templateEdit',
            component: () => import('@/views/factoryconfig/template/update/update'),
            meta: {
              breadcrumb: ['工厂配置', '设备模板', '编辑模板']
            }
          },
          {
            path: 'devcietype',
            name: 'devcietype',
            component: () => import('@/views/factoryconfig/devcietype/devcietype'),
            meta: {
              breadcrumb: ['工厂配置', '设备类型']
            }
          },
          {
            path: 'devcietype/add',
            name: 'factoryconfig_devcietype_add',
            component: () => import('@/views/factoryconfig/devcietype/update'),
            meta: {
              breadcrumb: ['工厂配置', '设备类型', '添加类型']
            }
          },

          {
            path: 'devcietype/edit/:deviceTypeId',
            name: 'factoryconfig_devcietype_edit',
            component: () => import('@/views/factoryconfig/devcietype/update'),
            props: true,
            meta: {
              breadcrumb: ['工厂配置', '设备类型', '编辑类型']
            }
          },
          {
            path: 'devcietype/detail/:deviceTypeId',
            name: 'factoryconfig_devcietype_detail',
            component: () => import('@/views/factoryconfig/devcietype/detail'),
            props: true,
            meta: {
              breadcrumb: ['工厂配置', '设备类型', '类型详情']
            }
          }
        ]
      },
      // {
      //   path: '/system',
      //   name: 'system',
      //   component: () => import('@/views/system/system'),
      //   children: [
      //     {
      //       path: 'company',
      //       name: 'company',
      //       component: () => import('@/views/system/company/company'),
      //       meta: {
      //         breadcrumb: ['系统设置', '企业管理']
      //       }
      //     },
      //     {
      //       path: 'company/detail/:id',
      //       name: 'system_company_detail',
      //       component: () => import('@/views/system/company/detail'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '企业管理', '企业详情']
      //       }
      //     },
      //     {
      //       path: 'company/add',
      //       component: () => import('@/views/system/company/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '企业管理', '添加企业']
      //       }
      //     },
      //     {
      //       path: 'company/edit/:id',
      //       name: 'system_company_edit',
      //       component: () => import('@/views/system/company/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '企业管理', '编辑企业']
      //       }
      //     },
      //     {
      //       path: 'personal',
      //       name: '人员管理',
      //       component: () => import('@/views/system/personal/personal'),
      //       meta: {
      //         breadcrumb: ['系统设置', '人员管理']
      //       }
      //     },
      //     {
      //       path: 'personal/detail/:id',
      //       name: 'system_personal_detail',
      //       component: () => import('@/views/system/personal/detail'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '人员管理', '人员详情']
      //       }
      //     },
      //     {
      //       path: 'personal/add',
      //       name: 'system_personal_add',
      //       component: () => import('@/views/system/personal/update'),
      //       meta: {
      //         breadcrumb: ['系统设置', '人员管理', '添加人员']
      //       }
      //     },
      //     {
      //       path: 'personal/edit/:id',
      //       name: 'system_personal_edit',
      //       component: () => import('@/views/system/personal/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '人员管理', '编辑人员']
      //       }
      //     },
      //     {
      //       path: 'role',
      //       name: '角色管理',
      //       component: () => import('@/views/system/role/role'),
      //       meta: {
      //         breadcrumb: ['系统设置', '角色管理']
      //       }
      //     },
      //     {
      //       path: 'role/add',
      //       name: 'system_role_add',
      //       component: () => import('@/views/system/role/update'),
      //       meta: {
      //         breadcrumb: ['系统设置', '角色管理', '添加角色']
      //       }
      //     },
      //     {
      //       path: 'role/edit/:id',
      //       name: 'system_role_edit',
      //       component: () => import('@/views/system/role/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '角色管理', '编辑角色']
      //       }
      //     },
      //     {
      //       path: 'role/detail/:id',
      //       name: 'system_role_detail',
      //       component: () => import('@/views/system/role/detail'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '角色管理', '角色详情']
      //       }
      //     },
      //     {
      //       path: 'permissions',
      //       name: '权限管理',
      //       component: () => import('@/views/system/permissions/permissions'),
      //       meta: {
      //         breadcrumb: ['系统设置', '权限管理']
      //       }
      //     },
      //     {
      //       path: 'permissions/add',
      //       name: 'system_permissions_add',
      //       component: () => import('@/views/system/permissions/update'),
      //       meta: {
      //         breadcrumb: ['系统设置', '权限管理', '添加权限']
      //       }
      //     },
      //     {
      //       path: 'permissions/edit/:id',
      //       name: 'system_permissions_edit',
      //       component: () => import('@/views/system/permissions/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '权限管理', '编辑权限']
      //       }
      //     },
      //     {
      //       path: 'permissions/detail/:id',
      //       name: 'system_permissions_detail',
      //       component: () => import('@/views/system/permissions/detail'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '权限管理', '权限详情']
      //       }
      //     },
      //     {
      //       path: 'functions',
      //       name: '功能管理',
      //       component: () => import('@/views/system/functions/functions'),
      //       meta: {
      //         breadcrumb: ['系统设置', '功能管理']
      //       }
      //     },
      //     {
      //       path: 'functions/add',
      //       name: 'system_functions_add',
      //       component: () => import('@/views/system/functions/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '功能管理', '添加功能']
      //       }
      //     },
      //     {
      //       path: 'functions/add/:parentId',
      //       name: 'system_functions_add:parentId',
      //       component: () => import('@/views/system/functions/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '功能管理', '添加功能']
      //       }
      //     },
      //     {
      //       path: 'functions/edit/:id',
      //       name: 'system_functions_edit',
      //       component: () => import('@/views/system/functions/update'),
      //       props: true,
      //       meta: {
      //         breadcrumb: ['系统设置', '功能管理', '编辑功能']
      //       }
      //     }
      //   ]
      // },
      {
        path: '/bugconfig',
        name: 'bugconfig',
        component: () => import('@/views/factoryconfig/bugconfig'),
        meta: {
          breadcrumb: ['工厂配置', '停机类型配置']
        }
      },

      {
        path: '/bugconfig/add/',
        name: 'bugconfig_add',
        component: () => import('@/views/factoryconfig/bugconfig/detail.vue'),
        meta: {
          breadcrumb: ['工厂配置', '停机类型配置', '添加']
        }
      },
      {
        path: '/bugconfig/add/:id',
        name: 'bugconfig_add:id',
        component: () => import('@/views/factoryconfig/bugconfig/detail.vue'),
        props: true,
        meta: {
          breadcrumb: ['工厂配置', '停机类型配置', '添加']
        }
      },
      {
        path: '/bugconfig/edit/:id/:level',
        name: 'bugconfig_edit:id',
        component: () => import('@/views/factoryconfig/bugconfig/detail.vue'),
        props: true,
        meta: {
          breadcrumb: ['工厂配置', '停机类型配置', '编辑']
        }
      },
      {
        path: '/LSDconfig',
        name: 'LSDconfig',
        component: () => import('@/views/factoryconfig/LSDconfig/index.vue'),
        props: true,
        meta: {
          breadcrumb: ['工厂配置', '看板配置']
        }
      },
      {
        path: '/deviceInfo',
        name: 'deviceInfo',
        component: () => import('@/views/deviceInfo/index.vue'),
        meta: {
          breadcrumb: ['设备管理']
        }
      },
      {
        path: '/deviceInfo/add/:id/:iconUrl',
        name: 'deviceInfo_add',
        component: () => import('@/views/deviceInfo/Adddeivce.vue'),
        props: true,
        meta: {
          breadcrumb: ['设备管理', '设备绑定'],
          menuActive: '/deviceInfo'
        }
      },
      {
        path: '/deviceInfo/detail',
        name: 'deviceInfo_detail',
        component: () => import('@/views/deviceInfo/deviceDetail/index.vue'),
        meta: {
          breadcrumb: ['设备管理', '设备详情'],
          menuActive: '/deviceInfo'
        }
      },
      {
        path: '/deviceInfo/detail/alarmAddRules',
        name: 'deviceInfo_detail_alarmAddRules',
        component: () => import('@/views/deviceInfo/deviceDetail/AlarmConfig/RulesConfig.vue'),
        meta: {
          breadcrumb: ['设备管理', '设备详情', '报警规则配置'],
          menuActive: '/deviceInfo'
        }
      },
      {
        path: '/deviceInfo/detail/alarmRulesDetail',
        name: 'deviceInfo_detail_alarmRulesDetail',
        component: () => import('@/views/deviceInfo/deviceDetail/AlarmConfig/alarmRulesDetail.vue'),
        meta: {
          breadcrumb: ['设备管理', '设备详情', '报警规则详情'],
          menuActive: '/deviceInfo'
        }
      },
      {
        path: '/publicparams',
        name: 'publicparams',
        component: () => import('@/views/deviceParams/publicParams/index.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '公共参数']
        }
      },
      {
        path: '/virtualParamGroup',
        name: 'virtualParamGroup',
        component: () => import('@/views/deviceParams/virtualParamGroup/index.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '虚拟参数组']
        }
      },
      {
        path: '/publicparams/edit',
        name: 'publicparams_edit',
        component: () => import('@/views/deviceParams/publicParams/editParams.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '公共参数', '公共参数编辑'],
          menuActive: '/publicparams'
        }
      },
      {
        path: '/basicParams/editParams',
        name: 'basicParams_editParams',
        component: () => import('@/views/deviceParams/publicParams/editParams.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '基础参数组', '基础参数组参数编辑'],
          menuActive: '/basicParams'
        }
      },
      {
        path: '/basicParams',
        name: 'basicParams',
        component: () => import('@/views/deviceParams/basicParams/index.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '基础参数组']
        }
      },
      {
        path: '/basicParams/edit',
        name: 'basicParams_edit',
        component: () => import('@/views/deviceParams/basicParams/editbasicParam.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '基础参数组', '基础参数组编辑'],
          menuActive: '/basicParams'
        }
      },
      {
        path: '/basicParams/info',
        name: 'basicParams_info',
        component: () => import('@/views/deviceParams/basicParams/intoParams.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '基础参数组', '基础参数组详情'],
          menuActive: '/basicParams'
        }
      },
      {
        path: '/virtualParamGroup/info',
        name: 'virtualParamGroup_info',
        component: () => import('@/views/deviceParams/virtualParamGroup/intoParams.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '虚拟参数组', '虚拟参数组详情'],
          menuActive: '/virtualParamGroup'
        }
      },
      {
        path: '/nestParams',
        name: 'nestParams',
        component: () => import('@/views/deviceParams/nestParams/index.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '嵌套参数组']
        }
      },
      {
        path: '/nestParams/edit',
        name: 'nestParams_edit',
        component: () => import('@/views/deviceParams/nestParams/editbasicParam.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '嵌套参数组', '嵌套参数组编辑'],
          menuActive: '/nestParams'
        }
      },
      {
        path: '/nestParams/info',
        name: 'nestParams_info',
        component: () => import('@/views/deviceParams/nestParams/intoParams.vue'),
        meta: {
          breadcrumb: ['设备参数管理', '嵌套参数组', '嵌套参数组详情'],
          menuActive: '/nestParams'
        }
      },
      {
        path: '/imgModel',
        name: 'imgModel',
        component: () => import('@/views/imgModel'),
        meta: {
          breadcrumb: ['工厂配置', '素材模型']
        }
      },
      {
        path: '/map3Dconfig',
        name: 'map3Dconfig',
        component: () => import('@/views/factoryconfig/map3Dconfig'),
        meta: {
          breadcrumb: ['工厂配置', '地图配置']
        }
      },
      {
        path: '/imgModel/img',
        name: 'imgModel_img',
        component: () => import('@/views/imgModel/intoFile.vue'),
        meta: {
          breadcrumb: ['工厂配置', '素材模型', '素材模型详情'],
          menuActive: '/imgModel'
        }
      },
      {
        path: '/virtualTemplate',
        name: 'virtualTemplate',
        component: () => import('@/views/virtualTemplate/index.vue'),
        meta: {
          breadcrumb: ['工厂配置', '虚拟模板']
        }
      },
      {
        path: '/virtualTemplate/:id',
        name: 'virtualTemplateDetail',
        component: () => import('@/views/virtualTemplate/detail'),
        props: true,
        meta: {
          breadcrumb: ['工厂配置', '虚拟模板', '虚拟模板详情'],
          menuActive: '/virtualTemplate'
        }
      },
      // {
      //   path: '/factoryConstour',
      //   name: 'factoryConstour',
      //   component: () => import('@/views/factoryConstour'),
      //   meta: {
      //     breadcrumb: ['工厂配置', '工厂结构']
      //   }
      // },
      {
        path: '/ParamsCharts',
        name: 'ParamsCharts',
        component: () => import('@/views/statisCharts/ParamsCharts/index.vue'),
        meta: {
          breadcrumb: ['统计报表', '参数统计']
        }
      },
      {
        path: '/OeeCharts',
        name: 'OeeCharts',
        component: () => import('@/views/statisCharts/OeeCharts/index.vue'),
        meta: {
          breadcrumb: ['统计报表', '效率趋势']
        }
      },
      {
        path: '/statusCharts',
        name: 'statusCharts',
        component: () => import('@/views/statisCharts/StatusCharts/index.vue'),
        meta: {
          breadcrumb: ['统计报表', '状态趋势']
        }
      },
      {
        path: '/PerfromCharts',
        name: 'PerfromCharts',
        component: () => import('@/views/statisCharts/PerfromCharts/index.vue'),
        meta: {
          breadcrumb: ['统计报表', '绩效排名']
        }
      },
      {
        path: '/AlaramCharts',
        name: 'AlaramCharts',
        component: () => import('@/views/statisCharts/AlarmCharts/index.vue'),
        meta: {
          breadcrumb: ['统计报表', '报警趋势']
        }
      },
      {
        path: '/BugCharts',
        name: 'BugCharts',
        component: () => import('@/views/statisCharts/BugCharts/index.vue'),
        meta: {
          breadcrumb: ['统计报表', '故障排名']
        }
      },
      {
        path: '/lostCharts',
        name: 'lostCharts',
        component: () => import('@/views/statisCharts/lostCharts/index.vue'),
        meta: {
          breadcrumb: ['统计报表', '损失分析']
        }
      },
      {
        path: '/user',
        name: 'user',
        component: () => import('@/views/user/user'),
        children: [
          {
            path: 'edit',
            name: 'user_edit',
            component: () => import('@/views/user/edit/edit'),
            meta: {
              breadcrumb: ['个人信息', '密码修改']
            }
          }
        ]
      }
    ]
  },
  {
    path: '/map',
    component: () => import('@/views/map/map'),
    children: [
      {
        path: 'playsettings',
        component: () => import('@/views/map/views/playSettings')
      }
    ]
  },
  {
    path: '/map/play',
    component: () => import('@/views/map/play')
  },
  {
    path: '/map/editor',
    name: 'map_editor',
    component: () => import('@/views/map/editor/editor'),
    children: [
      {
        path: 'bind',
        name: 'map_editor_bind',
        component: () => import('@/views/map/views/bind')
      },
      {
        path: 'add',
        name: 'map_editor_add',
        component: () => import('@/views/map/views/add')
      },
      {
        path: 'admin',
        name: 'map_editor_admin',
        component: () => import('@/views/map/views/admin')
      }
    ]
  },
  // 龙璟2.5D电子地图路由
  {
    path: '/map3Dupload',
    name: 'map3Dupload',
    component: () => import('@/views/map3D/3DloaderUpload'),
    meta: {
      breadcrumb: ['电子地图', '电子地图3D', '3D模型上传']
    }
  },
  {
    path: '/map3Dtag',
    name: 'map3Dtag',
    component: () => import('@/views/map3D/3DloaderLabel'),
    meta: {
      breadcrumb: ['电子地图', '电子地图3D', '3D模型标注']
    }
  },
  {
    path: '/map3Dshow',
    name: 'map3Dshow',
    component: () => import('@/views/map3D/3DMapShow'),
    meta: {
      breadcrumb: ['电子地图', '电子地图3D', '3D模型展示']
    }
  },
  {
    path: '/map2D',
    name: 'imgModel3d',
    component: () => import('@/views/map2D'),
    meta: {
      breadcrumb: ['电子地图', '电子地图2.5D']
    }
  },
  // holdhope 展厅展示电子地图路由
  {
    path: '/LSDTV',
    name: 'longjingLSDTV',
    component: () => import('@/views/LSDTV')
  },
  {
    path: '/MAPTV',
    name: 'longjingMAPTV',
    component: () => import('@/views/MAPTV')
  },
  {
    path: '/3dmap',
    name: 'holdhopeimgModel',
    component: () => import('@/views/holdhopemap3D'),
    meta: {
      breadcrumb: ['电子地图', 'holdhope电子地图3D']
    }
  },
  {
    path: '/factoryBoard',
    name: 'factoryBoard',
    component: () => import('@/views/LSD/index.vue')
  },

  {
    path: '/403',
    component: () => import('@/views/403/403.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

if (!offline) {
  router.beforeEach(async (to, from, next) => {
    if (to.query.token) {
      localStorage.setItem('token', to.query.token);
    }
    next(true);
    // const code = to.query.code;
    // const token = localStorage.getItem('token') || to.query.token;
    // if (code && !token) {
    // //   const getAccess = await import('./getAccess');
    // //   getAccess.default(code, to, next);
    // } else if (!code && !token) {
    // //   toAuthorization();
    //   next(false);
    // } else {
    // //   const storageAccess = await import('./storageAccess');
    // //   storageAccess.default(next);
    // //   menuActive(to);
    //   checkRouteAuth(to, next);
    // }
    // clearWindowInterVals();
  });
}

export default router;
