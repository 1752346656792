// 创建定时器集合
window.intervals = [];

// 创建命名空间
window.namespace = {
  // obj...
};

// 创建定时器命名空间
window.namespace.intervals = {
  map: {
    oee: null,
    status: null,
    warn: null
  }
};
