import axios from 'axios';
import dataModel from '../model';
import STATUS_CODE from '../code';
import { messageHandle, resStream, toAuthorization } from '../utils';

/**
 * 响应拦截器
 */
axios.interceptors.response.use(
  response => {
    const { config, headers, data } = response;
    const { code, message } = data;
    const method = config.method;
    const isStream = resStream(config.method, headers['content-type']);

    // Response Message
    messageHandle({ method, code, message });

    if (isStream) {
      return response.data;
    }
    // 根据 请求方式 更改响应数据结构
    switch (method) {
      case 'post':
      case 'put':
      case 'delete':
        response.data.data = dataModel({ ...data });
        break;
    }

    switch (code) {
      // 如果 token 错误
      case STATUS_CODE.TOKEN_ERR:
        toAuthorization();
        return Promise.reject(response.data.data);
      default:
        return response.data.data;
    }
  },
  error => {
    // Response Message
    messageHandle({ error });
    localStorage.clear();
    return Promise.reject(dataModel());
  }
);
