import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import interfaces from '@/axios/interfaces';
import ElementUI from 'element-ui';
import '@/common/js/index';
import '@/assets/delteFrame.css';
import 'element-ui/lib/theme-chalk/index.css';
import '@/common/style/index.scss';
import '@/assets/iconfont/iconfont.css';
import moment from 'moment';
import { deldialog } from '@/components/apicompontent.js';
Vue.prototype.$con = deldialog;

Vue.prototype.BASE_URL = process.env.VUE_APP_API_BASE_URL;
Vue.prototype.$VUE_APP_AESSETS_URL = process.env.VUE_APP_AESSETS_URL;

Vue.use(ElementUI);

Vue.config.productionTip = false;
Vue.prototype.$apis = interfaces;
Vue.prototype.$moment = moment;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
