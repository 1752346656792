<template>
  <el-dialog :title="content.title" :visible.sync="content.Deldisable" style="margin-top: 20vh" width="30%" :before-close="close">
    <span class="detail">{{ content.detail }}</span>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="ok">确 定</el-button>
      <!-- <el-button @click="quote">查看印用</el-button> -->
      <el-button @click="close">取 消</el-button>
    </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      content: {
        title: '',
        detail: '',
        Deldisable: false
      }
    };
  },
  methods: {
    close() {
      return false;
    },
    quote() {
      return false;
    },
    ok() {
      return false;
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .el-dialog {
  border-radius: 8px;
  .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    color: #333333;
  }
  .el-dialog__body {
    height: 180px;
    line-height: 100px;
    color: #121212;
    font-size: 18px;
    padding-left: 126px;
  }
  .el-button {
    width: 90px;
    height: 36px;
  }
  .detail {
    position: relative;
  }
  .detail::before {
    content: '';
    position: absolute;
    left: -50px;
    top: -10px;
    display: block;
    width: 40px;
    height: 40px;
    background-color: #f39800;
    border-radius: 50%;
    background-image: url('./assets/Delicon.png');
    background-size: 13px 20px;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
