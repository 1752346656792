import { get, del, post, put, formSubmit, queryUrl } from '../helpers';

const api = {
  nav_path: {
    get: get('oauth/navCenter')
  },
  logo: {
    get: get('oauth/tenantConfiguration')
  },
  // 公共参数api
  publicParams: {
    list: get('param/list'),
    del: del('param/del'),
    save: post('param/save'),
    update: put('param/update'),
    previewCite: get('param/previewCite')
  },
  // 基础组api
  basicParamsGroup: {
    list: get('paramGroup/listParamGroup'),
    save: post('paramGroup/saveBasicGroup'),
    update: put('paramGroup/updateBasicGroup'),
    del: del('paramGroup/delBasicGroup'),
    previewCite: get('paramGroup/previewCite'),
    downloadTemplate: queryUrl('paramGroup/downloadTemplate'),
    importParam: formSubmit('paramGroup/importParam'),
    ExportParam: queryUrl('paramGroup/exportParam'),
    exportValidParam: get('paramGroup/exportValidParam')
  },
  // 嵌套组
  NestGroup: {
    save: post('paramGroup/saveNestGroup'),
    del: del('paramGroup/delNestGroup'),
    update: put('paramGroup/updateNestGroup'),
    grouplist: get('paramGroup/listGroup'),
    paramslist: get('param/listAll'),
    nestGroupInfo: get('paramGroup/getGroup'), //修改详情
    GroupDetails: get('paramGroup/getGroupDetails'), //into嵌套组paramGroup/getGroupDetails
    basicGroupDetails: get('param/listBasicParam'), // type = 1 基础组下参数
    publicParams: get('groupToParam/listCommonParam'), // type = 2 嵌套组下公共参数
    exportNestParam: queryUrl('paramGroup/exportNestParam'), // type = 2 嵌套组下公共参数
    exportValidNestParam: get('paramGroup/exportValidNestParam')
  },
  // 素材库api
  imgModel: {
    fileList: get('pictureType/list'),
    listPicType: get('pictureType/listPicType'),
    imgList: get('picture/list'),
    addFile: post('pictureType/save'),
    delFile: del('pictureType/del'),
    upload: formSubmit('picture/upload', 'post'),
    downloadAll: queryUrl('picture/downloadFolder'),
    download: queryUrl('picture/downloadPic'),
    move: put('picture/movePic'),
    copy: put('picture/cpPic'),
    delImg: del('picture/del'),
    updataName: put('picture/updateName'),
    validPic: get('picture/validPic')
  },
  // 工厂结构api
  FactoryChart: {
    list: get('factoryModel/factoryModelTree'),
    // 顺序操作
    changeOrderNum: put('organization/changeFactoryOrderNum'),
    // 工厂操作
    addFactory: post('organization/save'),
    updateFactory: put('organization/update'),
    delFactory: del('organization/del'),
    // 车间操作
    addWorkShop: post('organization/save'),
    updateWorkShop: put('organization/update'),
    delWorkShop: del('organization/del'),
    // 产线操作
    addLine: post('organization/save'),
    updateLine: put('organization/update'),
    delLine: del('organization/del'),
    getCurrentTenantInfo: get('sysUser/getCurrentTenantInfo')
  },
  // 设备api
  device: {
    list: get('device/list'),
    schoolDeviceList: post('badevice/list'),
    del: del('device/del'),
    getbasic: get('device/get'),
    updatebasic: put('device/update'),
    delDeviceFile: del('device/delDeviceFile'),
    filedownload: queryUrl('file/download'),
    uploadDeviceFile: formSubmit('device/uploadDeviceFile'),
    listAll: get('sysUser/listAll'),
    getBaseInfo: get('device/getBaseInfo'),
    updateBaseInfo: put('device/updateBaseInfo'),
    getDeviceFile: get('device/getDeviceFile'),
    addDevice: post('device/save'),
    deviceType: get('deviceType/listAll'),
    deviceTemplate: get('deviceTemplate/listAll'),
    getinfo: get('device/get'),
    getDeviceInfo: post('badevice/detail'),
    getParamsName: get('monitor/getDeviceParam'),
    getParamsData: get('monitor/getDeviceParamInfo'),
    searchParamsData: get('monitor/getDeviceParamDetail'),
    exportParam: queryUrl('monitor/exportParam'),
    validExportParam: get('monitor/validExportParam'),
    moveDevice: put('device/v3.0/mvLineDevice'),
    bindingDevice: post('device/bindingDevice'),
    unbindDevice: post('device/unbindDevice')
  },
  deviceTypeVirtual: {
    deviceType: get('deviceType/listAllAndVirtual')
  },
  // 报警api
  Alarm: {
    Log: get('alarmLog/getAlarmLog'),
    TimeList: get('alarmLog/list'),
    SumList: get('alarmLog/getAlarmNumberList'),
    sustainTimerList: get('alarmLog/getAlarmTimeList'),
    exportValidAlarmLog: get('alarmLog/exportValidAlarmLog'),
    exportAlarm: queryUrl('alarmLog/exportAlarmLog')
  },
  // 设备效率api
  deviceOee: {
    EnterParamsList: get('oee/oeeValue/list'),
    EnterParamsSave: post('oee/oeeValue/save'),
    TodayDeviceOee: get('oee/realTimeOee'),
    deviceRunStatus: get('oee/deviceRuntimeStatus'),
    deviceRunLog: get('oee/deviceRuntimeRecord'),
    deviceOeeHistory: get('oee/deviceOeeHistory'),
    updateOeeStatus: put('device/v3.0/updateOeeStatus'),
    deviceRuntimeStatusOrigin: get('oee/deviceRuntimeStatusOrigin'),
    deviceRuntimeStatusAlarm: get('oee/deviceRuntimeStatusAlarm')
  },
  // 菜单api
  menu: {
    menu: get('sysUser/getCurrentMenuInfo')
  },
  Oeeconfig: {
    getName: get('oee/oeeName/list'),
    saveName: post('oee/oeeName/batchUpdate'),
    del: del('oee/oeeName/del'),

    getCalcTemplate: get('oee/calcTemplate/list'),
    saveCalcTemplate: post('oee/calcTemplate/saveOrUpdate')
  },
  AlarmConfig: {
    alarmlist: get('alarm/list'),
    getAlarmParam: get('alarm/getAlarmParam'),
    save: post('alarm/save'),
    update: put('alarm/update'),
    del: del('alarm/del'),
    get: get('alarm/get'),
    verifyExpression: post('alarm/verifyExpression')
  },
  paramsCharts: {
    getparams: get('reportParam/getReportFormParam'),
    getdata: get('reportParam/getParamValue')
  },
  Oeecharts: {
    get: get('reportDeviceOee')
  },
  statusCharts: {
    get: get('reportDeviceRunning')
  },
  perfromCharts: {
    post: get('reportDevicePerformance')
  },
  Alarmcharts: {
    get: get('reportAlarmLog/getAlarmLog')
  },
  Bugcharts: {
    bugNumber: get('reportAlarmLog/geAlarmNumError'),
    bugTime: get('reportAlarmLog/getAlarmTimeError')
  },
  lostCharts: {
    get: get('reportDeviceHaltFeedbackAnalysis')
  },
  LSD: {
    weekPerfrom: get('reportDevicePerformanceSummary'),
    statusTimer: get('reportDeviceStatusTimeSummary'),
    weekPerfromRank: get('reportDevicePerformanceRank'),
    deviceOee: get('reportDevicePerformanceTrend'),
    bugNumber: get('reportAlarmLog/alarmNumErrorKanban'),
    bugTimer: get('reportAlarmLog/alarmTimeErrorKanban'),
    Alarmcharts: get('reportAlarmLog/alarmTrendKanban'),
    deviceData: get('reportDeviceRunningCountSummary'),
    realDevice: get('reportAlarmLog/listTopTen'),
    AlarmDevice: get('reportAlarmLog/alarmException'),
    lineListAll: get('organization/lineListAll'),
    lineDeviceStatus: get('reportDeviceRunningDetails'),
    getDevice: get('panel/factoryPanel')
  },
  bugconfig: {
    get: get('oeeLostReason/page'),
    list: get('oeeLostReason/list'),
    save: post('oeeLostReason/save'),
    update: put('oeeLostReason/update'),
    del: del('oeeLostReason/delete'),
    detail: del('oeeLostReason/get')
  },
  lostAnalyze: {
    get: get('deviceHaltFeedback/page'),
    post: post('deviceHaltFeedback/feedback'),
    getCharts: get('deviceHaltFeedback/summary')
  },
  LSDconfig: {
    get: get('panel/list'),
    getdetail: get('panel/get'),
    getdevice: get('device/listAll'),
    getparams: get('panel/deviceParam'),
    save: post('panel/save'),
    update: put('panel/update'),
    del: del('panel/del')
  },
  longjing: {
    getparams: get('monitor/v3.0/listDeviceParam'),
    DeviceStatus: get('electron/deviceRunningStatus')
  },
  Map3d: {
    // 展示界面
    get: get('dimension/findModel'),
    // 模型上传
    upload: formSubmit('file/uploadDimension'),
    // title修改
    changeTitle: put('dimension/updateDimnName'),
    // 标注区域更新
    tagChange: put('dimension/updateModel'),
    // 3dMap标注重置
    reset: del('dimension/delete'),
    deviceData: get('dimension/deviceRunningNum'),
    oee: get('dimension/devicePerformance'),
    AlarmDevice: get('dimension/getAlarmInfo'),
    getparams: get('dimension/deviceParamInfo'),
    deviceOee: get('dimension/reportDevice'),
    isOpen: get('dimension/isUploadAll')
  },
  Map3dConfig: {
    get: get('dimensionPanel/list'),
    save: post('dimensionPanel/save'),
    update: put('dimensionPanel/update'),
    del: del('dimensionPanel/delete'),
    getdetail: get('dimensionPanel/getInfo'),
    getparams: get('dimensionPanel/deviceParam')
  },
  StateConfig: {
    getYield: get('deviceProConfig/get'),
    addYield: post('deviceProConfig/add'),
    getState: get('pulseState/get'),
    saveState: post('pulseState/saveOrUpdate')
  },
  runningState: {
    getProduceRealTime: get('deviceProuction/getProduceRealTime'),
    getDeviceProduce: get('deviceProuction/getDeviceProduce'),
    pulseState: get('device/pulseState')
  }
};
export default api;
