import Vue from 'vue';
import { RE_CLICK_TIMEER } from '/app.config';

const directives = [
  // 防重复点击
  {
    name: 'preventReclick',
    handles: {
      inserted(el, binding) {
        el.addEventListener('click', () => {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(() => {
              el.disabled = false;
            }, binding.value || RE_CLICK_TIMEER);
          }
        });
      }
    }
  }
];

directives.forEach(({ name, handles }) => {
  Vue.directive(name, handles);
});
