import axios from './axios/index';
import { querystringify } from './utils';

const API_BASE_URL = '/api/'; //process.env.VUE_APP_API_BASE_URL;

/**
 * @param {String} path 接口路径
 */
const queryUrl = path => {
  return function (params) {
    const queryParams = { ...params, Authorization: localStorage.getItem('token') };
    const url = API_BASE_URL + path + querystringify(queryParams);
    window.open(url);
  };
};

/**
 * @param {String} path 接口路径
 * @return {Promise}
 */
const get = path => {
  return function (params = {}) {
    return axios.get(API_BASE_URL + path, { params }).then(data => data);
  };
};

/**
 * @param {String} path 接口路径
 * @return {Promise}
 */
const post = path => {
  return function (params = {}) {
    if (params instanceof Array) {
      return axios
        .post(API_BASE_URL + path, params)
        .then(data => data)
        .catch(data => data);
    }
    return axios
      .post(API_BASE_URL + path, { ...params })
      .then(data => data)
      .catch(data => data);
  };
};

/**
 * @param {String} path 接口路径
 * @return {Promise}
 */
const put = path => {
  return function (params = {}) {
    if (params instanceof Array) {
      return axios
        .put(API_BASE_URL + path, params)
        .then(data => data)
        .catch(data => data);
    }
    return axios
      .put(API_BASE_URL + path, { ...params })
      .then(data => data)
      .catch(data => data);
  };
};

/**
 * @param {String} path 接口路径
 * @return {Promise}
 */
const del = path => {
  return function (params = {}) {
    return axios
      .delete(API_BASE_URL + path, { params })
      .then(data => data)
      .catch(data => data);
  };
};

/**
 * @param {String} path 接口路径
 * @param {String} method 请求方法
 * @return {Promise}
 */
const formSubmit = (path, method = 'post') => {
  return function (params = {}) {
    const formData = new FormData();

    for (const key in params) {
      if (key === 'files') {
        const files = params[key].map(file => file.raw).filter(file => file);

        files.forEach(raw => {
          formData.append('files', raw);
        });
      } else {
        formData.append(key, params[key]);
      }
    }

    return axios({
      method,
      url: API_BASE_URL + path,
      data: formData,
      headers: {
        'content-type': 'multipart/form-data'
      }
    })
      .then(data => data)
      .catch(data => data);
  };
};

export { get, post, put, del, formSubmit, queryUrl };
