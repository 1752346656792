import { get, post, put, del, formSubmit } from './helpers';
import api from './api/deviceParams.js';

export default {
  ...api,
  oauth: {
    authorize: get('oauth/authorize'),
    accessToken: post('oauth/accessToken'),
    logout: post('oauth/logout'),
    getCurrentUserAllPermissions: get('sysUser/getUserPermission')
  },
  paramGroup: {
    listAll: get('paramGroup/listAll')
  },
  deviceType: {
    listAll: get('deviceType/listAll'),
    list: get('deviceType/list'),
    get: get('deviceType/get'),
    save: post('deviceType/save'),
    update: put('deviceType/update'),
    del: del('deviceType/del')
  },
  deviceTemplate: {
    list: get('deviceTemplate/list'),
    save: formSubmit('deviceTemplate/save'),
    get: get('deviceTemplate/get'),
    del: del('deviceTemplate/del'),
    update: formSubmit('deviceTemplate/update', 'put'),
    getDeviceList: get('deviceTemplate/getDeviceList')
  },
  sysTenant: {
    list: get('sysTenant/list'),
    save: formSubmit('sysTenant/save'),
    get: get('sysTenant/get'),
    update: formSubmit('sysTenant/update', 'put')
  },
  sysAuth: {
    list: get('sysAuth/list'),
    save: post('sysAuth/save'),
    update: put('sysAuth/update'),
    del: del('sysAuth/del'),
    get: get('sysAuth/get'),
    permissionInfos: get('sysAuth/permissionInfos')
  },
  sysRole: {
    get: get('sysRole/get'),
    list: get('sysRole/list'),
    save: post('sysRole/save'),
    update: put('sysRole/update'),
    del: del('sysRole/del'),
    roleInfos: put('sysRole/roleInfos')
  },
  sysUser: {
    list: get('sysUser/list'),
    listAll: get('sysUser/listAll'),
    save: post('sysUser/save'),
    update: put('sysUser/update'),
    del: del('sysUser/del'),
    get: get('sysUser/getDetails'),
    getCurrentMenuInfo: get('sysUser/getCurrentMenuInfo'),
    getCurrentUserInfo: get('sysUser/getCurrentUserInfo'),
    updatePassword: put('sysUser/updatePassword')
  },
  sysMenu: {
    listAll: get('sysMenu/list'),
    get: get('sysMenu/get'),
    save: post('sysMenu/save'),
    update: put('sysMenu/update'),
    del: del('sysMenu/del'),
    order_update: put('sysMenu/preference/order/update')
  },
  sysQuickMenu: {
    update: put('sysQuickMenu/update')
  },
  electron: {
    get: get('electron/get'),
    del: del('electron/del'),
    list: get('electron/list'),
    save: post('electron/save'),
    update: put('electron/update'),
    deviceStatus: get('electron/deviceStatus'),
    deviceDetail: get('electron/deviceDetail'),
    electronList: get('electron/electronList'),
    updateName: put('electron/v3.0//updateName'),
    electronDetails: get('electron/electronDetails'),
    alarmException: get('electron/alarmException'),
    deviceRunningCount: get('electron/deviceRunningCount'),
    deviceRunningStatus: get('electron/deviceRunningStatus'),
    devicePerformanceSummaryReport: get('electron/devicePerformanceSummaryReport')
  }
};
