/**
 * 请求拦截器
 */
import qs from 'qs';
import axios from 'axios';
import { offline } from '/app.config';

const sourceCancel = axios.CancelToken.source();

axios.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization = token;
    if (config.method === 'get') {
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      };
    }
    if (offline) {
      config.data.cancelToken = sourceCancel.token;
    }
    return config;
  },
  function (error) {
    // 处理错误请求
    return Promise.reject(error);
  }
);
